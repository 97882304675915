/* ### DEFAULT VALUES - XS ### */

.w-100 {
    width: 100%;
}

.h-1rem {
    height: 1rem;
}

.h-2rem {
    height: 2rem;
}

.mw-20 {
    max-width: 20%;
}

.mw-75 {
    max-width: 75%;
}

.w-xs-100 {
    width: 100%;
}

.w-auto {
    width: auto !important;
}

.min-w-100-px {
    min-width: 100px;
}

.min-h-90-px {
    min-height: 90px;
}

.min-h-100-px {
    min-height: 100px;
}

.max-h-25-px {
    max-height: 25px;
}
.max-h-3rem {
    max-height: 3rem;
}

.max-h-5rem {
    max-height: 5rem;
}

.min-vh {
}

.width-fitcontent {
    width: fit-content;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .w-sm-100 {
        width: 100%;
    }

    .w-sm-auto {
        width: auto !important;
    }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .w-md-100 {
        width: 100%;
    }

    .w-md-auto {
        width: auto !important;
    }

    .h-md-100 {
        height: 100%;
    }

    .h-md-auto {
        height: auto;
    }

    .mw-md-10 {
        max-width: 10%;
    }

    .mw-md-15 {
        max-width: 15%;
    }

    .mw-md-280-px {
        max-width: 280px;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .w-lg-100 {
        width: 100%;
    }

    .w-lg-auto {
        width: auto !important;
    }

    .h-lg-100 {
        height: 100%;
    }

    .h-lg-auto {
        height: auto;
    }

    .mw-lg-10 {
        max-width: 10%;
    }

    .mw-lg-15 {
        max-width: 15%;
    }

    .mw-lg-20 {
        max-width: 20%;
    }

    .mw-lg-100 {
        max-width: 100%;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
    .w-xl-100 {
        width: 100%;
    }

    .w-xl-auto {
        width: auto !important;
    }

    .min-vh {
        min-height: 45vh;
    }
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
    .w-xxl-100 {
        width: 100%;
    }

    .w-xxl-auto {
        width: auto !important;
    }

    .max-w-xxl {
        max-width: 1140px;
    }

}
