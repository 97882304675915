/* ### DEFAULT VALUES - XS ### */

.trustpilot-widget.tb-stars {
    height: 150px; /* This needs to be set to 10px smaller than the TrustBox height */
    overflow: hidden;
}

    .trustpilot-widget.tb-stars iframe {
        left: -35%;
        width: 135% !important;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .trustpilot-widget.tb-stars iframe {
        left: 0;
        width: 100% !important;
    }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
