
/* ### DEFAULT VALUES - XS ### */

.container-fluid:has(.page) {
	background-color: #FCFCFC;
}

.header-img {
	background-position: top center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: auto;
}

.layout.page .page-intro.header-img {
	min-height: 500px;
}

.layout.page .page-intro {
	/*background-color: #ECECEC;*/ /*#331A4F;*/
	color: white;
	padding: 2rem 1rem;
	margin-bottom: 25px;
}
	.layout.page .page-intro .doing-right {
		height: 69px;
	}
		.layout.page .page-intro .doing-right img {
			max-height: 150px;
			position: absolute;
			right: 1rem;
		}

.layout.page:not(.home) .page-intro.header-img {
	min-height: 400px;
	background-position-y: center;
}

	.layout.page .page-content {
	}
		.layout.page .page-content .section-heading {
			padding-top: 2rem;
			padding-bottom: 1rem;
		}
			.layout.page .page-content .section-heading h2 {
				color: #5BC5BA;
				font-weight: bold;
			}
		.layout.page .page-content .section-content {
			padding-bottom: 2rem;
		}
			.layout.page .page-content .section-content h3 {
				color: #5BC5BA;
			}
			.layout.page .page-content .section-content a:not(.btn) {
				color: #EE4B78;
				text-decoration: none;
			}
				.layout.page .page-content .section-content a:not(.btn):hover {
					text-decoration: underline;
				}
		

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
	.layout.page .page-intro {
		padding: 50px 5rem;
	}
		.layout.page .page-intro .doing-right {
			height: 50px;
		}
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

	.layout.page {
		margin-top: 50px;
	}

	.layout.page .page-intro {
		padding: 1rem 2rem;
	}
		.layout.page .page-intro:not(.header-img) {
			padding: 50px 5rem 100px 5rem;
		}
		.layout.page .page-intro .doing-right {
			height: 0;
		}

	.layout.page .page-content .section-heading,
	.layout.page .page-content .section-content {
		padding-left: 4rem;
		padding-right: 4rem;
	}
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

	.header-img {
		background-position: top center; /* Center the image */
		background-repeat: no-repeat; /* Do not repeat the image */
		background-size: cover;
	}

	.layout.page .page-intro.header-img {
		min-height: 570px;
	}
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {

	.layout.page .page-intro {
		
	}
		.layout.page .page-intro.header-img > div {
			max-width: 1325px;
		}

	.layout.page:not(.home) .page-intro.header-img {
		min-height: 400px;
		background-position-y: center;
	}
	
	.layout.page.home .page-intro {
		
	}

		.layout.page.home .page-intro.header-img {
			min-height: 549px;
			/*background-size: contain;*/
			background-position-y: center;
		}
	

		.layout.page .page-intro .page-intro-content,
		.layout.page .page-content .section-heading,
		.layout.page .page-content .section-content {
			max-width: 1500px;
		}
}



/* 
Blizzard Blue: #aee0f0
Fountain Blue: #5bc5ba

Pukka Pink: #ee4c77
Freedom Purple: 
Action Blue: #326BB6 */
