/* ### DEFAULT VALUES - XS ### */

.sam-podcast-item {
	border-width: 3px;
	border-style: solid;
}

	.sam-podcast-item > .card-header {
		/*background-color: transparent;
		border-bottom: none;*/
	}

		.sam-podcast-item > .card-header.breakout-top {
			margin-top: -2.25rem;
		}

		.sam-podcast-item > .card-header img {
			max-height: 60px;
		}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}

