/* ### DEFAULT VALUES - XS ### */

.fb-form-group .fb-formlabel {
	font-weight: bold;
	margin-bottom: 0.75rem;
	font-size: 1.1rem;
	color: #666666;
}

.fb-form-group.has-helptext label {
	margin-bottom: 0;
}

/* RADIO BUTTONS */
.fb-form-group .fb-radio-btn {
	border: 2px solid #5d257c;
	border-radius: 1rem;
	min-width: 65px;
}
	.fb-form-group .fb-radio-btn:hover {
		color: #fff;
		background-color: rgba(58, 5, 138, 0.7);
	}

	.fb-form-group .btn-check:focus + .fb-radio-btn,
	.fb-form-group .fb-radio-btn:focus,
	.fb-form-group .fb-radio-btn:focus-within {
		border-color: #5d257c;
		box-shadow: 0 0 0 0.2rem rgba(58, 5, 138,.25);
	}

.fb-radio-container label {
	flex: 0 0 auto;
	width: 45%;
}

	.fb-radio-container label.space-buttons {
		margin-right: 0.5rem;
	}

.fb-radio-container.yes-no {
	max-width: 80%;
}


/* TEXT / SELECT */
.fb-form-group .form-control {
	border: 2px solid #5d257c;
	border-radius: 0.375rem;
}

	.fb-form-group .form-control:focus {
		border-color: #5d257c;
		box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
	}

.fb-form-group .input-group:focus-within {
	border-radius: 0.375rem;
	box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
}

	.fb-form-group .input-group:focus-within input {
		box-shadow: none;
	}

/* SEARCH SELECT */

.fb-form-group div[class*="-control"] {
	border: 2px solid #5d257c;
	border-radius: 0.375rem;
}

	.fb-form-group div[class*="-control"]:hover {
		border-color: #5d257c;
		box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
	}

	.fb-form-group div[class*="-control"]:focus-within {
		border-color: #5d257c;
		box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
	}

.fb-form-group .fb-select svg {
	color: #5d257c;
}

/* ERRORS / VALIDATION */

.fb-form-group.has-error label:not(.btn) {
	color: red;
}

.fb-form-group.has-error .form-control {
	border-color: red;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.fb-form-group.has-error textarea.form-control {
	background-position: right calc(0.375em + 0.1875rem) top calc(0.375em + 0.1875rem);
}
.fb-form-group.has-error div[class*="-control"] {
	border-color: red;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.fb-form-group.has-error .fb-radio-btn {
	border-color: red;
}

.fb-form-group.has-error .fb-select svg {
	color: red;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
	.fb-radio-container label:not(.space-buttons) {
		width: auto;
	}
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

	.fb-form-group .fb-formlabel {
		font-size: 1.2rem;
	}

	.fb-radio-container label {
		width: auto;
	}
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {

}
