/* ### DEFAULT VALUES - XS ### */

.career-item {
	border-width: 3px;
	border-style: solid;
	margin-bottom: 2rem;
}

	.career-item > .card-header {
		background-color: transparent;
		border-bottom: none;
	}

		.career-item > .card-header.breakout-top {
			margin-top: -1.5rem;
		}

		.career-item > .card-header img {
			max-height: 60px;
		}

		.career-item .card-body {
			padding: 0.25rem 2rem 0.5rem 2rem;
		}

		.career-item .card-footer {
			padding: 0.5rem 2rem 2rem 2rem;
		}

	.career-item .vacancy-details {
		font-size: 1.25rem;
	}

	.career-item .vacancy-details .label {
		font-weight: bold;
		color: #6B499C;
	}

	.career-item.pukka {
		border-color: #EE4C77;
	}

	.career-item.action {
		border-color: #326BB6;
	}

	.career-item.freedom {
		border-color: #6A4A9E;
	}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
