/* ### DEFAULT VALUES - XS ### */

.site-header-nav {
    background-color: #331A4F;
    backdrop-filter: blur(10px) !important;
}
    .site-header-nav .navbar-nav .nav-link {
        color: white;
        /*font-weight: bold;*/
    }

    .site-header-nav .navbar-nav .show > .nav-link,
    .site-header-nav .navbar-nav .nav-link.active {
        color: #EE4B78;
    }

    .site-header-nav .navbar-nav .nav-item img {
        display: none;
    }

    .site-header-nav .brand-img {
        padding-left: 1rem;
    }

.navbar-brand img {
    max-height: 50px;
}

.nav-section-header {
    font-size: 0.7rem;
}
/*
.nav-item.nav-home .nav-link.active {
	display: none;
}*/

.menu-float .dropdown-menu {
    position: absolute;
}

.navbar-toggler {
    min-width: 56px;
    min-height: 40px;
}

    .navbar-toggler .navbar-closeme {
        font-size: 1.75rem;
    }

    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon,
    .navbar-toggler[aria-expanded="false"] .navbar-closeme {
        display: none;
    }

    .navbar-toggler[aria-expanded="false"] .navbar-toggler-icon,
    .navbar-toggler[aria-expanded="true"] .navbar-closeme {
        display: inline-block;
    }

    .navbar-toggler[aria-expanded="false"] ~ .freedom-nav.collapsing {
        display: none;
    }

.freedom-nav {
    position: absolute;
    width: 100%;
    right: 0;
    top: 60px; /* Image plus 10px */
}

    .freedom-nav .navbar-nav {
        position: absolute;
        background-color: #331A4F;
        z-index: 1000;
        width: 100%;
        left: 0;
        border-bottom: 2px solid #646464;
    }

        .freedom-nav .navbar-nav .nav-item {
            font-size: 1rem;
            font-weight: bold;
            width: 100%;
            padding-right: 1rem;
        }

            .freedom-nav .navbar-nav .nav-item.menu-filler {
                height: 700px;
            }

            .freedom-nav .navbar-nav .nav-item.product-nav {
                width: 50%;
            }

        .freedom-nav .navbar-nav .nav-section-header {
            font-size: 0.9rem;
        }

.text-end.dropdown-menu[data-bs-popper] {
    right: 0;
    left: auto;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {

    .navbar-nav.btn-nav .nav-item .btn {
        font-size: 0.8rem;
    }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .navbar-nav.btn-nav .nav-item .btn {
        font-size: 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .site-header-nav {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

        .site-header-nav .navbar-nav .nav-item img {
            display: inline;
            max-width: 60px;
        }

        .site-header-nav .brand-img {
            padding-top: 0;
        }
    .navbar-brand img {
        max-height: 70px;
    }

    .freedom-nav {
        position: relative;
        top: auto;
        right: auto;
        width: auto;
    }

        .freedom-nav .navbar-nav {
            position: relative;
            border-bottom: none;
            background-color: transparent;
        }

            .freedom-nav .navbar-nav .nav-item:first-of-type {
                padding-top: 0;
            }

            .freedom-nav .navbar-nav .nav-item {
                font-size: 0.90rem;
                font-weight: normal;
                width: auto;
                padding-right: 0;
            }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
    .navbar-brand img {
        /*margin-top: 10px;*/
    }

    .freedom-nav .navbar-nav .nav-item {
        font-size: 1rem;
    }
}
