/* Blizzard Blue: #aee0f0
Fountain Blue: #5bc5ba

Pukka Pink: #ee4c77
Freedom Purple: 
Action Blue: #326BB6 */

.layout.page.menu .selected {
	color: red;
}
