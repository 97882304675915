/* ### MODAL VALUES - XS ### */

/* https://reactcommunity.org/react-modal/styles/ */

.modal-main {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	z-index: 99999;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	background-color: rgba(204, 204, 204, 0.8);
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
	.modal-main.date-change {
		max-width: 500px;
	}
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
	
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
	
}
