/* ### DEFAULT VALUES - XS ### */

.company-item.card {
    border-width: 3px;
    border-style: solid;
}

.company-item > .card-header {
    background-color: transparent;
    border-bottom: none;
}

    .company-item > .card-header.breakout-top {
        margin-top: -2.25rem;
    }

    .company-item > .card-header img {
        max-height: 60px;
    }

.company-item.pukka {
    border-color: #EE4C77;
}

.company-item.action {
    border-color: #326BB6;
}

.company-item.freedom {
    border-color: #6A4A9E;
}

.company-item img {
    max-height: 60px;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}

