/* ### DEFAULT VALUES - XS ### */

.card.clickable {
    cursor: pointer;
}

.card.clickable:hover {
    background-color: aliceblue;
}

.page-intro-card {
    color: #331A4F;
    padding: 0.25rem 0.5rem;
    background-color: #AFE1F270;
    border-radius: 1rem;
    backdrop-filter: blur(5px) !important;
}
    .page-intro-card .card-body {
        font-size: 1.25rem;
        text-align: center;
        font-weight: 500;
        padding: 0.5rem;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .page-intro-card {
        padding: 0.5rem 0.5rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .page-intro-card {
        min-width: 275px;
        max-width: 400px;
        bottom: 2rem;
    }
        .page-intro-card .card-body {
            font-size: 1.75rem;
        }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
    .page-intro-card {
    }
}
