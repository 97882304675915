/* ### DEFAULT VALUES - XS ### */

footer {
    text-align: center;
    background-color: #331A4F;
}

    footer .footer-links {
        /*font-weight: bold;*/
    }

        footer .footer-links a {
            color: #fff;
            text-decoration: none;
            display: inline-block;
        }
            footer .footer-links a:hover {
                color: rgba(255, 255, 255, 0.75);
            }

            footer .footer-links a:not(.social):hover {
                text-decoration: underline;
            }

        footer .footer-links .nav-link {
            padding: 0.3rem 1rem;
        }

    footer .social-media-links {
        padding-left: 0.25rem;
        padding-top: 0.5rem;
    }

    footer .legal-footer {
        color: white;
        padding-left: 1.5rem;
    }

    footer .legal-footer .brand-img {
        max-height: 75px;
        margin-bottom: 1rem;
    }


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    footer .legal-footer {
        border-left: 2px solid white;
        padding-left: 5rem;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
    
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
