/* ### DEFAULT VALUES - XS ### */

.debug-resolution-finder {
    /*display: none;*/
    position: absolute;
}

.navbar-dark .debug-resolution-finder {
    color: white;
}

.debug-image {
    display: none;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
