/* ### DEFAULT VALUES - XS ### */

.li-py-2 li {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
    .li-py-2 li:last-child {
        padding-bottom: 0;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
