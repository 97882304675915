/* ### DEFAULT VALUES - XS ### */

.carousel .inner-carousel {
    
}

.carousel .carousel-item {
    min-height: 275px;
}

.carousel .carousel-img {
    max-height: 75px;
    width: auto;
    max-width: 100%;
}

.carousel .carousel-card {
    width: 100%;
    min-height: 335px;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .carousel .carousel-item {
        min-height: 95px;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .carousel .carousel-img {
        max-height: 100px;
        width: auto;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
    .carousel .carousel-img {
        max-height: 125px;
        width: auto;
    }
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
    .carousel .carousel-card {
        width: 100%;
        min-height: 450px;
    }

        .carousel .carousel-card img {
            max-height: 500px;
        }
}
